import React, { useState, useEffect } from 'react';
import EditVideoModal from './EditVideoModal';
import 'bootstrap/dist/css/bootstrap.min.css';

function ManageVideos() {
  const [videos, setVideos] = useState([]);
  const [newVideo, setNewVideo] = useState({
    title: '',
    description: '',
    isDRM: false,
    videoUrl: '',
    drmInfo: '', // 初始化为字符串
    sponsorFormUrl: ''
  });

  // 加载视频列表
  useEffect(() => {
    fetch('https://vpi.nogi46.me/api/videos')
      .then(response => response.json())
      .then(data => setVideos(data))
      .catch(error => console.error('Error fetching videos:', error));
  }, []);

  // 处理表单输入变化
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setNewVideo(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  const [editingVideoId, setEditingVideoId] = useState(null);

  // 打开编辑模态框
  const openEditModal = (id) => {
    setEditingVideoId(id);
  };

  // 关闭编辑模态框
  const closeEditModal = () => {
    setEditingVideoId(null);
  };

  // 提交新视频
  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://vpi.nogi46.me/api/videos', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newVideo)
    })
    .then(response => response.json())
    .then(data => {
      setVideos([...videos, data]);
      // 重置表单
      setNewVideo({
        title: '',
        description: '',
        isDRM: false,
        videoUrl: '',
        drmInfo: '',
        sponsorFormUrl: ''
      });
    })
    .catch(error => console.error('Error adding video:', error));
  };

  // 在组件的状态中添加一个新变量
  const [showCopyAlert, setShowCopyAlert] = useState(false);
  
  // handleCopyLink 更新为
  const handleCopyLink = (videoId) => {
    const url = `https://stream.nogi46.me/video/${videoId}`; // 根据实际部署的URL地址修改
    navigator.clipboard.writeText(url).then(() => {
      setShowCopyAlert(true);
      setTimeout(() => setShowCopyAlert(false), 3000); // 3秒后隐藏提示
    }).catch(err => {
      console.error('Error copying link: ', err);
    });
  };

  return (
    <div className="container mt-4">
      <h2>Manage Videos</h2>
      <div className="mb-4">
        <h3>Add New Video</h3>
        <form onSubmit={handleSubmit} className="mb-3">
          <div className="mb-3">
            <input type="text" className="form-control" name="title" value={newVideo.title} onChange={handleChange} placeholder="Title" required />
          </div>
          <div className="mb-3">
            <input type="text" className="form-control" name="description" value={newVideo.description} onChange={handleChange} placeholder="Description" />
          </div>
          <div className="mb-3 form-check">
            <input type="checkbox" className="form-check-input" name="isDRM" checked={newVideo.isDRM} onChange={handleChange} id="isDRM" />
            <label className="form-check-label" htmlFor="isDRM">DRM</label>
          </div>
          <div className="mb-3">
            <input type="text" className="form-control" name="videoUrl" value={newVideo.videoUrl} onChange={handleChange} placeholder="Video URL" required />
          </div>
          {newVideo.isDRM && (
            <div className="mb-3">
              <label htmlFor="drmInfo" className="form-label">DRM Key</label>
              <input type="text" className="form-control" id="drmInfo" name="drmInfo" value={newVideo.drmInfo} onChange={handleChange} required />
            </div>
          )}
          <div className="mb-3">
            <input type="text" className="form-control" name="sponsorFormUrl" value={newVideo.sponsorFormUrl} onChange={handleChange} placeholder="Sponsor Form URL" />
          </div>
          <button type="submit" className="btn btn-success">Add Video</button>
        </form>
      </div>
      <div className="mt-4">
        <h3>Existing Videos</h3>
        <ul className="list-group">
          {videos.map(video => (
            <li key={video._id} className="list-group-item d-flex justify-content-between align-items-center">
              {video.title} - {video.isDRM ? 'DRM' : 'Non-DRM'}
              <div>
                <button className="btn btn-primary btn-sm mr-2" onClick={() => openEditModal(video._id)}>Edit</button>
                <button className="btn btn-secondary btn-sm" onClick={() => handleCopyLink(video._id)}>Copy Link</button>
              </div>
            </li>
          ))}
        </ul>
        {showCopyAlert && <div className="copy-alert show">Link copied to clipboard!</div>}
      </div>


      {editingVideoId && <EditVideoModal videoId={editingVideoId} closeModal={closeEditModal} />}
    </div>
  );
}

export default ManageVideos;
