import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 引入本地翻译文件
import translationZH from './locales/zh-TW/translation.json';
import translationEN from './locales/en/translation.json';
import translationJA from './locales/ja/translation.json';

const resources = {
    'zh-TW': {
      translation: translationZH
    },
    en: {
      translation: translationEN
    },
    ja: {
      translation: translationJA
    }
  };

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'zh-TW', // 默认语言
    keySeparator: false, // 我们不使用键的嵌套
    interpolation: {
      escapeValue: false, // React 已经安全地处理转义
    },
  });

export default i18n;
