import React, { useState, useEffect } from 'react';

function EditVideoModal({ videoId, closeModal }) {
  const [videoData, setVideoData] = useState({
    title: '',
    description: '',
    isDRM: false,
    videoUrl: '',
    drmInfo: '', // 直接初始化为字符串
    allowedUsers: [],
    sponsorFormUrl: ''
  });  
  const [newEmail, setNewEmail] = useState('');

  // 加载视频数据
  useEffect(() => {
    fetch(`https://vpi.nogi46.me/api/videos/${videoId}`)
      .then(response => response.json())
      .then(data => setVideoData(data));
  }, [videoId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (name === 'drmInfo') {
      setVideoData(prevState => ({
        ...prevState,
        drmInfo: value // 直接更新 drmInfo 字段，作为字符串处理
      }));
    } else {
      setVideoData(prevState => ({
        ...prevState,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };
  

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleAddEmail = () => {
    if (newEmail && !videoData.allowedUsers.includes(newEmail)) {
      setVideoData({ ...videoData, allowedUsers: [...videoData.allowedUsers, newEmail] });
      setNewEmail('');
    }
  };

  const handleRemoveEmail = (email) => {
    setVideoData({
      ...videoData,
      allowedUsers: videoData.allowedUsers.filter(e => e !== email)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`https://vpi.nogi46.me/api/videos/${videoId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(videoData)
    })
    .then(() => closeModal());
  };

  return (
    <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
  <div className="modal-dialog modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Edit Video</h5>
        <button type="button" className="btn-close" onClick={closeModal}></button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="title" className="form-label">Title</label>
            <input type="text" className="form-control" id="title" name="title" value={videoData.title} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description</label>
            <textarea className="form-control" id="description" name="description" rows="3" value={videoData.description} onChange={handleChange}></textarea>
          </div>
          <div className="mb-3 form-check">
            <input type="checkbox" className="form-check-input" id="isDRM" name="isDRM" checked={videoData.isDRM} onChange={handleChange} />
            <label className="form-check-label" htmlFor="isDRM">DRM</label>
          </div>
          <div className="mb-3">
            <label htmlFor="videoUrl" className="form-label">Video URL</label>
            <input type="text" className="form-control" id="videoUrl" name="videoUrl" value={videoData.videoUrl} onChange={handleChange} required />
          </div>
          {videoData.isDRM && (
            <div className="mb-3">
              <label htmlFor="drmInfo" className="form-label">DRM Key</label>
              <input 
                type="text" 
                className="form-control" 
                id="drmInfo" 
                name="drmInfo" 
                value={videoData.drmInfo} // 确保这里是字符串
                onChange={handleChange} 
                required 
              />
            </div>
          )}
          <div className="mb-3">
            <label htmlFor="sponsorFormUrl" className="form-label">Sponsor Form URL</label>
            <input type="text" className="form-control" id="sponsorFormUrl" name="sponsorFormUrl" value={videoData.sponsorFormUrl} onChange={handleChange} />
          </div>
          <div className="mb-3">
          <label htmlFor="allowedUsers" className="form-label">Allowed Users (Emails)</label>
            <div className="input-group mb-3">
              <input type="email" className="form-control" placeholder="Enter email" value={newEmail} onChange={handleEmailChange} />
              <button type="button" className="btn btn-outline-secondary" onClick={handleAddEmail}>Add</button>
            </div>
            <ul className="list-group">
              {videoData.allowedUsers.map(email => (
                <li key={email} className="list-group-item d-flex justify-content-between align-items-center">
                  {email}
                  <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemoveEmail(email)}>Remove</button>
                </li>
              ))}
            </ul>
          </div>
          <button type="submit" className="btn btn-primary">Update Video</button>
        </form>
      </div>
    </div>
  </div>
</div>

  );
}

export default EditVideoModal;
