import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

function ChatRoom({ videoId, username }) {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const messagesEndRef = useRef(null);
    const socket = useRef(null);
  
    useEffect(() => {
      socket.current = io('https://vpi.nogi46.me');
  
      socket.current.emit('join room', videoId);
  
      socket.current.on('chat message', (messageData) => {
        setMessages((prevMessages) => [...prevMessages, messageData]);
      });
  
      return () => socket.current.disconnect();
    }, [videoId]);

    useEffect(() => {
        const chatRoomElement = document.querySelector('.chat-room-messages');
        chatRoomElement.scrollTop = chatRoomElement.scrollHeight; // 滾動到底部
    }, [messages]); // 依賴於messages的變化
    
  
    const sendMessage = () => {
      if (message.trim()) {
        socket.current.emit('chat message', videoId, username, message);
        setMessage('');
      }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    return (
        <div className="chat-room">
          <div className="chat-room-messages">
            {messages.map((msg, index) => (
              <div key={index} className="chat-room-message">
                <strong>{msg.username}: </strong>{msg.text}
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-room-input">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
            />
            <button onClick={sendMessage}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      );
      
}

export default ChatRoom;
