import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from './ThemeContext';
import SDK from 'casdoor-js-sdk'; // 导入 Casdoor SDK
import ManageVideos from './components/ManageVideos';
import VideoPlayer from './components/VideoPlayer';
import HomePage from './components/HomePage';
import './App.css';


function App() {
  const { t, i18n } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [language, setLanguage] = useState('zh-TW');
  const { toggleTheme } = useTheme();

  // 初始化 Casdoor SDK
  const sdkConfig = {
    serverUrl: "https://sso.nogi46.me", // Casdoor 服务器地址
    clientId: 'db5ed1cb0232538cce80',
    clientSecret: '054f789198587ab6e1d4bdc2285a86bfa9fcca9a',
    organizationName: 'eystudio',
    applicationName: 'streaming',
    redirectPath: "https://stream.nogi46.me/", // 您的前端回调路径
  };
  const sdk = new SDK(sdkConfig);

  const handleChangeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  };
  const isTokenExpired = () => {
    const expiresAt = localStorage.getItem('expires_at');
    return new Date().getTime() > expiresAt;
  };

  // 登录函数
  const handleLogin = () => {
    window.location.href = sdk.getSigninUrl();
  };
  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_at');
    setUserInfo(null);
    window.location.href = '/';
  };
  

  // 从 URL 中获取授权码，并交换用户信息
  useEffect(() => {
    const storedToken = localStorage.getItem('access_token');
    if (storedToken && !isTokenExpired()) {
      // 如果 token 存在且未过期
      // 使用 token 请求用户信息
      fetch(`https://vpi.nogi46.me/auth/userinfo?access_token=${storedToken}`)
        .then(resp => resp.json())
        .then(userInfo => {
          setUserInfo(userInfo); // 设置用户信息
        })
        .catch(err => console.error('Error fetching user info:', err));
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        fetch('https://vpi.nogi46.me/auth/callback', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code })
        })
        .then(response => response.json())
        .then(data => {
          if (data.access_token) {
            // 存储 access_token 和过期时间
            const expiresIn = 168 * 60 * 60 * 1000; // 168小时
            const expiresAt = new Date().getTime() + expiresIn;
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('expires_at', expiresAt);
            fetch(`https://vpi.nogi46.me/auth/userinfo?access_token=${data.access_token}`)
              .then(resp => resp.json())
              .then(userInfo => {
                setUserInfo(userInfo); // 设置用户信息
              })
              .catch(err => console.error('Error fetching user info:', err));
          }
        })
        .catch(error => console.error('Error:', error));
      }
    }
  }, []);  

  return (
    <Router>
    <div>
      <header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
        <img src="https://nogi46.me/hotlink-ok/logo.png" alt="Logo" style={{ width: '100px' }} />
        <nav>
        <button onClick={userInfo ? handleLogout : handleLogin}>
          {userInfo ? t('logout') : t('login')}
        </button>
          <select value={language} onChange={handleChangeLanguage} style={{ padding: '5px 10px', margin: '0 10px' }}>
            <option value="en">English</option>
            <option value="zh-TW">繁體中文</option>
            <option value="ja">日本語</option>
          </select>
          <button onClick={toggleTheme}>{t('toggleTheme')}</button>
        </nav>
      </header>
      <Routes>
        <Route path="/" element={<HomePage userInfo={userInfo} />} />
        <Route path="/video/:videoId" element={<VideoPlayer />} /> {/* 新的视频播放路由 */}
      </Routes>
      <footer style={{ textAlign: 'center', marginTop: '20px' }}>
        {/* 添加页脚信息 */}
      </footer>
    </div>
    </Router>
  );
}

export default App;
