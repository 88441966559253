import React, { useState, useEffect } from 'react';
import ManageVideos from './ManageVideos';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function HomePage({ userInfo }) {
  const { t } = useTranslation();
  const [videos, setVideos] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState('');

  // 請求有許可權的影片列表
  useEffect(() => {
    if (userInfo) {
      const accessToken = localStorage.getItem('access_token');
      if (accessToken) {
        fetch(`https://vpi.nogi46.me/auth/userinfo?access_token=${accessToken}`)
          .then(response => response.json())
          .then(userInfo => {
            fetch(`https://vpi.nogi46.me/api/videos/permissions?email=${userInfo.email}`)
              .then(response => response.json())
              .then(data => {
                setVideos(data);
              })
              .catch(error => {
                console.error('Fetching videos error:', error);
                setError("Error fetching videos.");
              });
          })
          .catch(error => {
            console.error('Fetching user info error:', error);
            setError("Error fetching user information.");
          });
      }
    }
  }, [userInfo]);

  const openVideoPage = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  // 根據使用者角色渲染內容
  const renderContentBasedOnRole = () => {
    if (!userInfo) return <div>{t('loading')}</div>;

    if (userInfo.role === 'admin') {
      return (
        <>
          <div>{t('welcomeUser')} {userInfo.username}</div>
          <ManageVideos /> {/* 管理員可以看到影片管理介面 */}
        </>
      );
    } else {
      return (
        <>
          <div>{t('welcomeUser')} {userInfo.username}</div>
          <div className="mt-4">
            <h3>{t('availableVideos')}</h3>
            <ul className="list-group">
              {videos.map(video => (
                <li key={video.id} className="list-group-item d-flex justify-content-between align-items-center">
                  {video.title}
                  <button className="btn btn-primary btn-sm" onClick={() => openVideoPage(video.id)}>{t('openVideo')}</button>
                </li>
              ))}
            </ul>
            {error && <div>{error}</div>}
          </div>
        </>
      );
    }
  };

  return (
    <main style={{ padding: '20px' }}>
      <h1>{t('welcome')}</h1>
      {renderContentBasedOnRole()}
    </main>
  );
}

export default HomePage;
