import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChatRoom from './ChatRoom';
import { ReactShakaPlayer } from '@mkhuda/react-shaka-player';
import { useTranslation } from 'react-i18next';
import io from 'socket.io-client';


function VideoPlayer() {
  const { t, i18n } = useTranslation();
  const { videoId } = useParams();
  const [videoInfo, setVideoInfo] = useState(null);
  const [error, setError] = useState(null);
  const [viewers, setViewers] = useState(0);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const socket = io('https://vpi.nogi46.me');
    if (accessToken && videoId) {
      socket.emit('join video', videoId);

      socket.on('viewers update', (newViewersCount) => {
        setViewers(newViewersCount);
      });
      // 加入视频房间
      
      fetch(`https://vpi.nogi46.me/auth/userinfo?access_token=${accessToken}`)
        .then(response => response.json())
        .then(userInfo => {
          setUsername(userInfo.username);
          fetch(`https://vpi.nogi46.me/api/videos/${videoId}/info?email=${userInfo.email}`)
            .then(response => {
              if (!response.ok) {
                if (response.status === 403) {
                  response.json().then(data => {
                    setError(t("accessDeniedMessage"));
                    setVideoInfo({sponsorFormUrl: data.sponsorFormUrl}); // Set default or empty object to avoid undefined error
                  });
                } else {
                  setError("An error occurred while fetching video information.");
                }
              } else {
                response.json().then(data => {
                  setVideoInfo(data);
                });
              }
            })
            .catch(error => {
              console.error('Fetching video info error:', error);
              setError("Error fetching video information.");
            });
        })
        .catch(error => {
          console.error('Fetching user info error:', error);
          setError("Error fetching user information.");
        });
    } else {
      setError("Please login to view this video.");
    }
    return () => {
      socket.disconnect();
    };
  }, [videoId]);


  let drmConfig = {};
  if (videoInfo && videoInfo.drmInfo) {
    const [keyId, key] = videoInfo.drmInfo.split(':');
    drmConfig = {
      drm: {
        clearKeys: {
          [keyId]: key
        }
      }
    };
  }


  if (error) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h2>{error}</h2>
        {videoInfo && videoInfo.sponsorFormUrl && (
          <a href={videoInfo.sponsorFormUrl} target="_blank" rel="noopener noreferrer">Sponsor Now</a>
        )}
      </div>
    );
  }

  if (!videoInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="video-chat-container" style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '1200px', margin: '0 auto', flexDirection: 'row' }}>
      <div className="video-container" style={{ flex: '2', marginBottom: '20px' }}>
        <div id="video-container" style={{ marginBottom: '20px' }}>
          {/* 视频播放器内容 */}
          <ReactShakaPlayer
                autoPlay
                src={videoInfo.videoUrl}
                config={drmConfig}
                width="100%"
                height="auto"
            />
        </div>
        {/* 视频信息 */}
        <div>
          <h2 style={{ fontSize: '24px', textAlign: 'left', margin: '10px 0' }}>{videoInfo.title}</h2>
          <div style={{ fontSize: '16px', textAlign: 'left', margin: '10px 0' }}>
            {videoInfo.description.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="chat-room" style={{ flex: '1' }}>
        {/* 聊天室 */}
        <ChatRoom videoId={videoId} username={username}/>
      </div>
    </div>
  );
}

export default VideoPlayer;
